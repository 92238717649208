import { getImage } from 'gatsby-plugin-image'

import processLine from '~/assets/images/services/line-five-points.svg?file'
import DevelopmentProcess from '~/components/blocks/DevelopmentProcess'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/mvp-app-development/Anchors'
import MvpAppDevBenefits from '~/mock/services/mvp-app-development/MvpAppDevBenefits'
import MvpAppDevExpertise from '~/mock/services/mvp-app-development/MvpAppDevExpertise'
import MvpAppDevFAQ from '~/mock/services/mvp-app-development/MvpAppDevFAQ'
import MvpAppDevIndustries from '~/mock/services/mvp-app-development/MvpAppDevIndustries'
import MvpAppDevPractices from '~/mock/services/mvp-app-development/MvpAppDevPractices'
import MvpAppDevProcess from '~/mock/services/mvp-app-development/MvpAppDevProcess'
import MvpAppDevServices from '~/mock/services/mvp-app-development/MvpAppDevServices'
import MvpAppDevTabContent from '~/mock/services/mvp-app-development/MvpAppDevTabContent'
import MvpAppDevTabList from '~/mock/services/mvp-app-development/MvpAppDevTabList'
import MvpAppDevTypes from '~/mock/services/mvp-app-development/MvpAppDevTypes'
import { cx, fileToImageLikeData } from '~/utils'
import CompanyProjectsLogoSection from '~/views/Homepage/components/CompanyProjectsLogoSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import TableCostMvpAppDev from '~/views/Services/components/ServicesTables/TableCostMvpAppDev'
import ServicesTechStackSectionCrossPlatform from '~/views/Services/components/ServicesTechStackSectionCrossPlatform'
import useMvpAppDevelopmentStaticQuery from '~/views/Services/MvpAppDevelopment/useMvpAppDevelopmentStaticQuery'

import * as containerStyles from './MvpAppDevelopment.module.scss'

const MvpAppDevelopment = () => {
  const query = useMvpAppDevelopmentStaticQuery()
  const mvpAppDevTabContent = MvpAppDevTabContent()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))

  return (
    <MainLayout scrollTop>
      <section className={containerStyles.mvpAppDevPrimeSection}>
        <PrimeSectionServices
          titleTop="MVP App Development"
          title="Services"
          description="A minimum viable product (MVP) app development is not a way to make quick money but a chance to test your unique business idea. Let’s do it together!"
          breadcrumbSecondLevel="Services"
          breadcrumbSecondLevelPath="/services/"
          breadcrumbCurrent="MVP App Development Services"
          breadcrumbCurrentPath="/services/mvp-app-development/"
        />
      </section>

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.mvpAppDevServicesSection}
      >
        <HeadSection
          position="center"
          title="Our exceptional MVP app development services"
          description="The mobile app development MVP concept is a powerful tool for both software development and business. Creating a minimum viable product and quickly attracting the first users makes it possible to reduce risks and costs and improve competitiveness."
          descriptionLarge
        />
        <Service3DIconCard dataContent={MvpAppDevServices} />
      </section>

      <section
        id="industries"
        className={containerStyles.mvpAppDevIndustriesSection}
      >
        <HeadSection
          position="center"
          title="MVP app development for various industries"
          description="In fact, MVP in mobile app development has helped various businesses quickly test their ideas and get feedback from real users. This allowed them to focus on developing and improving key features that were in demand in the market. Many startups successfully moved from a simple MVP to creating significant and popular platforms."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={MvpAppDevIndustries} />
      </section>

      <CallToAction
        title="Looking for an MVP app development company?"
        subtitle="Our team will bring your idea into reality."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.mvpAppDevTypesSection} id="types">
        <HeadSection
          position="center"
          title="Exploring diverse types of MVP app development"
          description="It is vital to dive into the world of MVP app development with our diverse exploration of two contrasting yet equally compelling approaches: the low-fidelity MVP and the high-fidelity MVP."
          descriptionLarge
        />
        <ServicesIndustriesCard isColTwo dataContent={MvpAppDevTypes} />
      </section>

      <section
        id="benefits"
        className={containerStyles.mvpAppDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Essential benefits of MVP app development"
          description="The main advantages of MVP app development services are that they allow you to focus on crucial aspects of the product and avoid wasting resources on unnecessary features."
          descriptionLarge
        />
        <ServicesIconSmallCard isColSm dataContent={MvpAppDevBenefits} />
      </section>

      <section
        id="process"
        className={cx(
          containerStyles.mvpAppDevProcessSection,
          'mvpAppDevProcessSection',
        )}
      >
        <HeadSection
          position="center"
          title="From concept to triumph: our MVP app development process"
        />
        <DevelopmentProcess
          dataImage={processLine}
          dataContent={MvpAppDevProcess}
        />
      </section>

      <section className={containerStyles.mvpAppDevTechSection} id="tech-stack">
        <HeadSection
          position="center"
          title="Cutting-edge tech stack for MVP excellence"
          description="This meticulously crafted tech stack is our ultimate gateway to transforming your MVP app development dreams into a sensational digital experience. Our specialists have collected an array of languages, frameworks, and tools that harmoniously coexist, moving your app’s journey to outstanding heights."
          descriptionLarge
        />
        <ServicesTechStackSectionCrossPlatform
          isCrossPlatformMobileAppTech
          isAndroidTech
          isIosTech
        />
      </section>

      <section className={containerStyles.mvpAppDevCostSection} id="costs">
        <HeadSection
          position="center"
          title="Navigating the costs of MVP app development"
          description="You can confidently navigate the detailed currents of app development MVP, empowering you to optimize your budget, reduce risks, and move your idea toward substantial realization."
          descriptionLarge
        />
        <TableCostMvpAppDev />
      </section>

      <section
        id="practices"
        className={containerStyles.mvpAppDevPracticesSection}
      >
        <HeadSection
          position="center"
          title="Mastering the art of MVP app development: best practices"
          description="Are you ready to elevate your MVP app development prowess? Then, uncover the most promising practices of building MVPs, sharpening your skills to lead the business frontier."
          titleLarge
          descriptionLarge
        />

        <ServicesIconSmallCard isBorder dataContent={MvpAppDevPractices} />
      </section>

      <CallToAction
        title="Have a great idea for app development?"
        subtitle="Let’s create it together."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.mvpAppDevChoiceSection}>
        <HeadSection
          position="center"
          title="Your MVP app development partner of choice"
          description="Codica is more than a development company - we’re your strategic ally, innovation enabler, and trusted guide through the dynamic landscape of app development. Regarding MVPs, we’re not just building products; we're crafting success stories."
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'At Codica, we understand that your minimum viable product is the crucial stepping stone toward a groundbreaking app that captivates users. With an exceptionally talented team, we boast an impressive track record of creating cutting-edge MVPs for startups, small and mid-scale businesses, and entrepreneurs across diverse industries.',
            },
            {
              text: 'Choosing Codica as your MVP app development company is a partnership that exceeds traditional client-vendor relationships. Our transparent communication, exceptional project management, and dedication to meeting deadlines ensure that you have complete confidence in the development journey.',
            },
          ]}
        />
      </section>

      <LazyHydrate whenVisible>
        <section className={containerStyles.mvpAppDevProjLogoSection}>
          <CompanyProjectsLogoSection isCenter />
        </section>
      </LazyHydrate>

      <section className={containerStyles.mvpAppDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Our journey in MVP app development"
          description="For over eight years, the Codica team has succeeded in delivering various high-quality and secure app products and solutions."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={MvpAppDevExpertise} />
      </section>

      <section className={containerStyles.mvpAppDevCaseSection} id="cases">
        <HeadSection position="left" title="Our portfolio" />
        <DiscoverWorksTabs
          dataTabList={MvpAppDevTabList}
          dataContent={mvpAppDevTabContent}
        />
      </section>

      <section className={containerStyles.mvpAppDevClientQuotes}>
        <HeadSection
          position="center"
          title="Success stories"
          description="Clients from various sectors, spanning e-commerce, healthcare, finance, and beyond, commend our ability to breathe life into their visions and empower them with a competitive edge in today’s dynamic business landscape. Each testimonial is a testament to our core values: innovation, integrity, and an unwavering dedication to surpassing clients’ expectations."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build a robust app product that your users will love!"
          widthTitle="800px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our expert mobile app developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={MvpAppDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default MvpAppDevelopment
