// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "G_gt";
export var mvpAppDevCaseSection = "G_gD";
export var mvpAppDevChoiceSection = "G_gz";
export var mvpAppDevClientQuotes = "G_gF";
export var mvpAppDevCostSection = "G_gx";
export var mvpAppDevExpertiseSection = "G_gC";
export var mvpAppDevIndustriesSection = "G_gr";
export var mvpAppDevPracticesSection = "G_gy";
export var mvpAppDevPrimeSection = "G_gp";
export var mvpAppDevProcessSection = "G_gv";
export var mvpAppDevProjLogoSection = "G_gB";
export var mvpAppDevServicesSection = "G_gq";
export var mvpAppDevTechSection = "G_gw";
export var mvpAppDevTypesSection = "G_gs";